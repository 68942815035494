import React, { useEffect, useState } from "react"

import SEO from "components/seo"
import Layout from "components/layout"
import { GlobalCover } from "components/global/cover/cover"
import { ProjectDetailsHeadline } from "components/pages/development/project/details/headline"
import { ProjectDetailsInfrastructure } from "components/pages/development/project/details/infrastructure"
import { ProjectDetailsGallery } from "components/pages/development/project/details/gallery"
import { ProjectDetailsTenants } from "components/pages/development/project/details/tenants"
import { YoutubeFrame } from "components/global/youtube-frame/youtube-frame"
import { ProjectDetailsLocation } from "components/pages/development/project/details/location"
import { ProjectDetailsNearby } from "components/pages/development/project/details/nearby"
import { GlobalMarketingGallery } from "components/global/marketing-gallery/marketing-gallery"
import { ProjectDetailsLeadForm } from "components/pages/development/project/details/lead-form"
import { NavBottom } from "components/anti/nav-bottom/nav-bottom"
import { Map } from "components/anti/map/map"
import { ProjectDetailsVirtualTour } from "components/pages/development/project/details/virtual-tour"
import ProjectDetailsDataProject from "../components/pages/development/project/details/data-project"
import { Breadcrumb } from "components/anti/breadcrumb/breadcrumb-duti"

import { WithLocation } from "components/anti/utils/utils"
import MicrodataProject from "components/microdata-project";
import Helmet from "react-helmet";
import {Card} from "components/anti/card/card";
import {Button} from "components/anti/buttons/buttons";


const ProjectDetails = ({ pageContext, location, search }) => {
	// const dataNearby = useStaticQuery(GET_NEARBY)
	const lang = pageContext.langKey
	const redirectAmpHtml = process.env.GATSBY_ROOT_URL + location.pathname + "/"

	const [coverBottom, setCoverBottom] = useState({})

	const projectLocations = pageContext?.data?.projectLocations?.nodes[0]?.name
	const projectCategories = pageContext?.data?.projectCategories?.nodes[0]?.name

	const dataLang = lang === "en"
		? pageContext?.data
		: pageContext.data?.translation
		|| pageContext?.data

	const data = dataLang?.projectDetailsPage
	// const navBottomData = lang === "en"
	//     ? pageContext.data.projectDetailsPage.navBottom
	//     : pageContext.data?.translation?.projectDetailsPage?.navBottom
	//     || pageContext.data.projectDetailsPage.navBottom

	const eBrochures = data?.eBrochures

	const nearbyData = pageContext?.data?.projectLocations?.nodes[0]?.projects?.nodes

	const { seo } = lang === "en"
		? pageContext?.data
		: pageContext?.data?.translation
		|| pageContext?.data

	const slug = pageContext?.data?.slug
	const SEODefaultImg = data?.cover?.image?.sourceUrl

	const schemaMarkup = {}

	useEffect(() => {
		let pathSection = location?.pathname.split('/')[3]
		let projectIdx = pageContext?.projects.findIndex(el => el.slug === pathSection)
		const prevProj = pageContext?.projects[projectIdx - 1 < 0 ? pageContext?.projects?.length - 1 : projectIdx - 1]
		const nextProj = pageContext?.projects[projectIdx + 1 > pageContext?.projects?.length - 1 ? 0 : projectIdx + 1]

		setCoverBottom({
			prev: {
				path: `/development/${prevProj.category}/${prevProj.slug}`,
				image: prevProj?.image?.sourceUrl, // change this with image cover
				title: prevProj?.name,
				slug: prevProj?.slug,
			},
			next: {
				path: `/development/${nextProj.category}/${nextProj.slug}`,
				image: nextProj?.image?.sourceUrl, // change this with image cover
				title: nextProj?.name,
				slug: nextProj?.slug,
			}
		})
	}, [])

	const longitude = data?.location?.map?.longitude
	const latitude = data?.location?.map?.latitude
	const bgColor = dataLang?.backgroundColor?.backgroundColor?.color
	const utmId = search?.utm_id ? search?.utm_id : ""
	const datalayerAdd = data?.dataLayer

	const dataLayerValue = () => {
		const value = {
			'event': 'ViewContent',
			'page_url': window.location.href, // entire string of current page URL including UTM parameter and hash # if any
			'page_title': data.cover?.title,
			'content_type': 'home_listing',
			'content_id': slug, // any IDs in your listing catalog, example: 'Provence Suites'
			'city': projectLocations, // provide the user's city of the interest, example 'BSD City'
			'region': projectLocations, // state, district, or region of interest, example 'South Tangerang'
			'country': 'Indonesia', // target country of the interest, example 'Indonesia'
			'lease_start_date': datalayerAdd?.leaseStartDate,
			'lease_end_date': datalayerAdd?.leaseEndDate,
			'currency': 'IDR',
			'preferred_price_range': datalayerAdd?.preferredPriceRange, // provide in floating data type [500000000.00, 1000000000.00]
			'preferred_baths_range': datalayerAdd?.preferredBathsRange, // provide in integer data type: [1, 2] 'preferred_bed_range': 'bed range', // provide in integer data type: [2, 3]
			'property_type': projectCategories // example value: 'apartment' or 'condo' or 'house' or 'land' or 'manufactured' or 'other' or' townhouse'
		}

		return value
	}


	useEffect(() => {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push(dataLayerValue())
	}, [])

	return (
		<Layout path={location.pathname} lang={lang} theme={bgColor || "light"} isAmp>
			<Helmet>
				<html amp="" />
				<link rel="canonical" href={redirectAmpHtml.replace("/development/amp/", "/development/")} />
				<style amp-boilerplate="">
					{`
            body {
              -webkit-animation: -amp-start 8s steps(1, end) 0s 1 normal both;
              -moz-animation: -amp-start 8s steps(1, end) 0s 1 normal both;
              -ms-animation: -amp-start 8s steps(1, end) 0s 1 normal both;
              animation: -amp-start 8s steps(1, end) 0s 1 normal both;
            }
            @-webkit-keyframes -amp-start {
              from {
                visibility: hidden;
              }
              to {
                visibility: visible;
              }
            }
            @-moz-keyframes -amp-start {
              from {
                visibility: hidden;
              }
              to {
                visibility: visible;
              }
            }
            @-ms-keyframes -amp-start {
              from {
                visibility: hidden;
              }
              to {
                visibility: visible;
              }
            }
            @-o-keyframes -amp-start {
              from {
                visibility: hidden;
              }
              to {
                visibility: visible;
              }
            }
            @keyframes -amp-start {
              from {
                visibility: hidden;
              }
              to {
                visibility: visible;
              }
            }
          `}
				</style>
				<noscript>
					{`
            <style amp-boilerplate="">
              body {
                -webkit-animation: none;
                -moz-animation: none;
                -ms-animation: none;
                animation: none;
              }
            </style>
          `}
				</noscript>
				<style type="text/css" amp-custom="">
					{`
              @font-face {
                font-family: 'DM Sans';
                font-style: italic;
                font-weight: 400;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Fp2ywxg089UriCZa4ET-DJF4e8BH9.woff2) format('woff2');
                unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
              }
              
              @font-face {
                font-family: 'DM Sans';
                font-style: italic;
                font-weight: 400;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Fp2ywxg089UriCZa4Hz-DJF4e8A.woff2) format('woff2');
                unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
              }
              
              @font-face {
                font-family: 'DM Sans';
                font-style: italic;
                font-weight: 500;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Ap2ywxg089UriCZaw7ByWB3wT2iv06JOy.woff2) format('woff2');
                unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
              }
              
              @font-face {
                font-family: 'DM Sans';
                font-style: italic;
                font-weight: 500;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Ap2ywxg089UriCZaw7ByWCXwT2iv06A.woff2) format('woff2');
                unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
              }
              
              @font-face {
                font-family: 'DM Sans';
                font-style: italic;
                font-weight: 700;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Ap2ywxg089UriCZawpBqWB3wT2iv06JOy.woff2) format('woff2');
                unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
              }
              
              @font-face {
                font-family: 'DM Sans';
                font-style: italic;
                font-weight: 700;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Ap2ywxg089UriCZawpBqWCXwT2iv06A.woff2) format('woff2');
                unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
              }
              
              @font-face {
                font-family: 'DM Sans';
                font-style: normal;
                font-weight: 400;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Hp2ywxg089UriCZ2IHTWEBlwu8Q.woff2) format('woff2');
                unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
              }
              
              @font-face {
                font-family: 'DM Sans';
                font-style: normal;
                font-weight: 400;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Hp2ywxg089UriCZOIHTWEBlw.woff2) format('woff2');
                unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
              }
              
              @font-face {
                font-family: 'DM Sans';
                font-style: normal;
                font-weight: 500;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriAWCrCBamC3YU-CnE6Q.woff2) format('woff2');
                unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
              }
              
              @font-face {
                font-family: 'DM Sans';
                font-style: normal;
                font-weight: 500;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriAWCrCBimC3YU-Ck.woff2) format('woff2');
                unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
              }
              
              @font-face {
                font-family: 'DM Sans';
                font-style: normal;
                font-weight: 700;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriASitCBamC3YU-CnE6Q.woff2) format('woff2');
                unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
              }
              
              @font-face {
                font-family: 'DM Sans';
                font-style: normal;
                font-weight: 700;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/dmsans/v11/rP2Cp2ywxg089UriASitCBimC3YU-Ck.woff2) format('woff2');
                unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
              }
              *,
              :after,
              :before {
                  box-sizing: border-box;
              }
              footer,
              main,
              nav,
              section {
                  display: block;
              }
              body {
                  background-color: #fff;
                  color: #333;
                  font-family: DM Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
                  font-size: 1rem;
                  font-weight: 400;
                  line-height: 1.5;
                  margin: 0;
                  text-align: left;
              }
              h1,
              h2,
              h4,
              h5 {
                  margin-bottom: 0.5rem;
                  margin-top: 0;
              }
              p {
                  margin-bottom: 1rem;
                  margin-top: 0;
              }
              ol,
              ul {
                  margin-bottom: 1rem;
              }
              ol,
              ul {
                  margin-top: 0;
              }
              ul ul {
                  margin-bottom: 0;
              }
              strong {
                  font-weight: bolder;
              }
              small {
                  font-size: 80%;
              }
              a {
                  background-color: transparent;
                  color: #bd0e13;
              }
              a,
              a:hover {
                  text-decoration: none;
              }
              a:hover {
                  color: #76090c;
              }
              img {
                  border-style: none;
              }
              img,
              svg {
                  vertical-align: middle;
              }
              svg {
                  overflow: hidden;
              }
              button {
                  border-radius: 0;
              }
              button:focus:not(:focus-visible) {
                  outline: 0;
              }
              button,
              input {
                  font-family: inherit;
                  font-size: inherit;
                  line-height: inherit;
                  margin: 0;
              }
              button,
              input {
                  overflow: visible;
              }
              button {
                  text-transform: none;
              }
              button::-moz-focus-inner {
                  border-style: none;
                  padding: 0;
              }
              .h5,
              footer h5,
              h1,
              h2,
              h4,
              h5 {
                  font-weight: 700;
                  line-height: 1.2;
                  margin-bottom: 0.5rem;
              }
              small {
                  font-size: 87.5%;
                  font-weight: 400;
              }
              .social {
                  list-style: none;
                  padding-left: 0;
              }
              .img-fluid {
                  height: auto;
                  max-width: 100%;
              }
              .container,
              .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-content,
              .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-header {
                  margin-left: auto;
                  margin-right: auto;
                  padding-left: 0.625rem;
                  padding-right: 0.625rem;
                  width: 100%;
              }
              @media (min-width: 576px) {
                  .container,
                  .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-content,
                  .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-header {
                      max-width: 540px;
                  }
              }
              @media (min-width: 768px) {
                  .container,
                  .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-content,
                  .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-header {
                      max-width: 720px;
                  }
              }
              @media (min-width: 992px) {
                  .container,
                  .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-content,
                  .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-header {
                      max-width: 960px;
                  }
              }
              @media (min-width: 1200px) {
                  .container,
                  .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-content,
                  .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-header {
                      max-width: 1140px;
                  }
              }
              @media (min-width: 1400px) {
                  .container,
                  .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-content,
                  .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-header {
                      max-width: 1320px;
                  }
              }
              .row {
                  display: flex;
                  flex-wrap: wrap;
                  margin-left: -0.625rem;
                  margin-right: -0.625rem;
              }
              .order-md-last {
			      order: 13;
			  }
              .col-12,
              .col-3,
              .col-6,
              .col-lg-4,
              .col-md-3,
              .col-md-4,
              .col-md-6,
              .col-md-8 {
                  padding-left: 0.625rem;
                  padding-right: 0.625rem;
                  position: relative;
                  width: 100%;
              }
              .col-3 {
                  flex: 0 0 25%;
                  max-width: 25%;
              }
              .col-6 {
                  flex: 0 0 50%;
                  max-width: 50%;
              }
              .col-12 {
                  flex: 0 0 100%;
                  max-width: 100%;
              }
              @media (min-width: 768px) {
                  .col-md-3 {
                      flex: 0 0 25%;
                      max-width: 25%;
                  }
                  .col-md-4 {
                      flex: 0 0 33.33333%;
                      max-width: 33.33333%;
                  }
                  .col-md-6 {
                      flex: 0 0 50%;
                      max-width: 50%;
                  }
                  .col-md-8 {
                      flex: 0 0 66.66667%;
                      max-width: 66.66667%;
                  }
                  .offset-md-1 {
                      margin-left: 8.33333%;
                  }
              }
              @media (min-width: 992px) {
                  .col-lg-4 {
                      flex: 0 0 33.33333%;
                      max-width: 33.33333%;
                  }
              }
              .form-control {
                  background-clip: padding-box;
                  background-color: #fff;
                  border: 1px solid #ccc;
                  border-radius: 0;
                  color: #333;
                  display: block;
                  font-size: 1rem;
                  font-weight: 400;
                  height: calc(1.5em + 1.875rem + 2px);
                  line-height: 1.5;
                  padding: 0.9375rem 0.75rem;
                  width: 100%;
              }
             
              .field-basic .el-underline,.field-basic .field-underline .select__control:after,.field-underline .field-basic .select__control:after {
					display: none
				}
				
				.field-underline label {
					margin-bottom: 0
				}
				
				.field-underline .form-control {
					border-color: transparent;
					border-left: 0;
					border-radius: 0;
					border-right: 0;
					padding-left: 0;
					padding-right: 0
				}
				
				.field-underline .form-control:focus {
					box-shadow: none
				}
				
				.field-underline .select__control {
					border-color: transparent;
					border-radius: 0
				}
				
				.field-underline .select__control:after {
					content: ""
				}
				
				.field-underline .select__control .select__value-container {
					padding-left: 0;
					padding-right: 0
				}
				
				.field-underline .select__control .select__value-container:after {
					right: 1px
				}
				
				.field-underline .select__control.select__control--menu-is-open {
					border-color: transparent;
					box-shadow: none
				}
				
				.field-underline.field-floating .form-control-wrapper label {
					left: 0;
					padding: .9375rem 0;
					top: 0;
					color: #b3b3b3;
					font-size: 1rem;
					height: 100%;
					margin-bottom: 0;
					pointer-events: none;
					position: absolute;
					transform-origin: 0 0;
					transition: opacity .1s ease-in-out,transform .1s ease-in-out;
				}
				
				.field-underline.field-floating .form-control-wrapper .form-control:focus~label,.field-underline.field-floating .form-control-wrapper .form-control:not(:placeholder-shown)~label {
					color: #b3b3b3;
					transform: scale(.8) translateY(-.75rem) translateX(0)
				}
				
				.field-underline.field-floating .form-select-wrapper label {
					color: #b3b3b3;
					left: 0;
					padding: .9375rem 0;
					top: 0;
					transform: scale(.8) translateY(-.75rem) translateX(0)
				}
				.field-floating .form-control-wrapper .form-control,.field-floating .form-control-wrapper .select__control .select__value-container,.field-floating .form-control-wrapper .select__control .select__value-container .select__placeholder,.field-floating .form-control-wrapper .select__control .select__value-container .select__single-value,.field-floating .form-select-wrapper .form-control,.field-floating .form-select-wrapper .select__control .select__value-container,.field-floating .form-select-wrapper .select__control .select__value-container .select__placeholder,.field-floating .form-select-wrapper .select__control .select__value-container .select__single-value {
					padding-bottom: .4375rem;
					padding-top: 1.4375rem;
				}
				.field-floating .form-control-wrapper .form-control::placeholder,.field-floating .form-control-wrapper .select__control .select__value-container .select__placeholder::placeholder,.field-floating .form-control-wrapper .select__control .select__value-container .select__single-value::placeholder,.field-floating .form-control-wrapper .select__control .select__value-container::placeholder,.field-floating .form-select-wrapper .form-control::placeholder,.field-floating .form-select-wrapper .select__control .select__value-container .select__placeholder::placeholder,.field-floating .form-select-wrapper .select__control .select__value-container .select__single-value::placeholder,.field-floating .form-select-wrapper .select__control .select__value-container::placeholder {
					color: transparent
				}
				.field-floating>label {
					display: none;
				}
				
				.el-underline,.field-underline .select__control:after {
					background: #ccc;
					bottom: 0;
					height: 1px;
					pointer-events: none;
					position: absolute;
					transition: all .2s cubic-bezier(.55,.085,.68,.53);
					width: 100%
				}
				
				.el-underline-focus,.field-underline .form-control:focus~.el-underline,.field-underline .form-control:focus~.select__control:after,.field-underline .select__control.select__control--menu-is-open:after {
					background: #bd0e13;
					bottom: -1px;
					height: 2px
				}
              @media (prefers-reduced-motion: reduce) {
                  .form-control {
                      transition: none;
                  }
              }
              .form-control::-ms-expand {
                  background-color: transparent;
                  border: 0;
              }
              .form-control:-moz-focusring {
                  color: transparent;
                  text-shadow: 0 0 0 #333;
              }
              .form-control:focus {
                  background-color: #fff;
                  border-color: #bd0e13;
                  box-shadow: 0 0 0 0.1rem #bd0e13;
                  color: #333;
                  outline: 0;
              }
              .form-control::-webkit-input-placeholder {
                  color: #b3b3b3;
                  opacity: 1;
              }
              .form-control:-ms-input-placeholder {
                  color: #b3b3b3;
                  opacity: 1;
              }
              .form-control::placeholder {
                  color: #b3b3b3;
                  opacity: 1;
              }
              .form-control:disabled {
                  background-color: #e5e5e5;
                  opacity: 1;
              }
              .form-group {
                  margin-bottom: 1rem;
              }
              .row-4 {
					margin-left: -1.5rem;
					margin-right: -1.5rem
				}
				.form-row {
					display: flex;
					flex-wrap: wrap;
					margin-left: -5px;
					margin-right: -5px
				}
              .btn {
                  background-color: transparent;
                  border: 1px solid transparent;
                  border-radius: 0;
                  color: #333;
                  display: inline-block;
                  font-weight: 400;
                  line-height: 1.5;
                  padding: 0.9375rem 1.5rem;
                  text-align: center;
                  -webkit-user-select: none;
                  -ms-user-select: none;
                  user-select: none;
                  vertical-align: middle;
              }
              @media (prefers-reduced-motion: reduce) {
                  .btn {
                      transition: none;
                  }
              }
              .btn:hover {
                  color: #333;
                  text-decoration: none;
              }
              .btn:focus {
                  box-shadow: 0 0 0 0.1rem #bd0e13;
                  outline: 0;
              }
              .btn:disabled {
                  opacity: 0.65;
              }
              .btn-primary {
                  background-color: #bd0e13;
                  border-color: #bd0e13;
                  color: #fff;
              }
              .btn-primary:focus,
              .btn-primary:hover {
                  background-color: #990b0f;
                  border-color: #8e0a0e;
                  color: #fff;
              }
              .btn-primary:focus {
                  box-shadow: 0 0 0 0 rgba(199, 50, 54, 0.5);
              }
              .btn-primary:disabled {
                  background-color: #bd0e13;
                  border-color: #bd0e13;
                  color: #fff;
              }
              .btn-link {
                  color: #bd0e13;
                  font-weight: 400;
                  text-decoration: none;
              }
              .btn-link:hover {
                  color: #76090c;
                  text-decoration: none;
              }
              .btn-link:focus {
                  text-decoration: none;
              }
              .btn-link:disabled {
                  color: #666;
                  pointer-events: none;
              }
              .btn-block {
                  display: block;
                  width: 100%;
              }
              .nav-link {
                  display: block;
                  padding: 0.5rem 1rem;
              }
              .nav-link:focus,
              .nav-link:hover {
                  text-decoration: none;
              }
              .navbar {
                  align-items: center;
                  flex-wrap: wrap;
                  justify-content: space-between;
                  padding: 0.5rem 1rem;
                  position: relative;
              }
              .navbar .container,
              .navbar .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-content,
              .navbar .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-header {
                  align-items: center;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: space-between;
              }
              .navbar-brand {
                  display: inline-block;
                  font-size: 1.25rem;
                  line-height: inherit;
                  margin-right: 1rem;
                  padding-bottom: 0.3125rem;
                  padding-top: 0.3125rem;
                  white-space: nowrap;
              }
              @media (max-width: 1200px) {
                  .navbar-brand {
                      font-size: calc(1.025rem + 0.3vw);
                  }
              }
              .navbar-brand:focus,
              .navbar-brand:hover {
                  text-decoration: none;
              }
              .navbar-nav {
                  display: flex;
                  flex-direction: column;
                  list-style: none;
                  margin-bottom: 0;
                  padding-left: 0;
              }
              .navbar-nav .nav-link {
                  padding-left: 0;
                  padding-right: 0;
              }
              @media (min-width: 1200px) {
                  .navbar-expand-xl {
                      flex-flow: row nowrap;
                      justify-content: flex-start;
                  }
                  .navbar-expand-xl .navbar-nav {
                      flex-direction: row;
                  }
                  .navbar-expand-xl .navbar-nav .nav-link {
                      padding-left: 0.5rem;
                      padding-right: 0.5rem;
                  }
              }
              .navbar-light .navbar-brand,
              .navbar-light .navbar-brand:focus,
              .navbar-light .navbar-brand:hover {
                  color: rgba(0, 0, 0, 0.9);
              }
              .navbar-light .navbar-nav .nav-link {
                  color: rgba(0, 0, 0, 0.5);
              }
              .navbar-light .navbar-nav .nav-link:focus,
              .navbar-light .navbar-nav .nav-link:hover {
                  color: rgba(0, 0, 0, 0.7);
              }
              .bg-light {
                  background-color: #fafafa;
              }
              .d-none {
                  display: none;
              }
              .d-flex {
                  display: flex;
              }
              .flex-wrap {
				  flex-wrap: wrap;
			  }
              @media (min-width: 768px) {
                  .d-md-block {
                      display: block;
                  }
              }
              @media (min-width: 1200px) {
                  .d-xl-none {
                      display: none;
                  }
                  .d-xl-flex {
                      display: flex;
                  }
              }
              .justify-content-between {
                  justify-content: space-between;
              }
              .align-items-center {
                  align-items: center;
              }
              .position-relative {
                  position: relative;
              }
              .fixed-top {
                  top: 0;
              }
              .fixed-top {
                  left: 0;
                  position: fixed;
                  right: 0;
                  z-index: 1030;
              }
              .my-2 {
                  margin-top: 0.5rem;
              }
              .mr-2 {
                  margin-right: 0.5rem;
              }
              .mb-2,
              .my-2 {
                  margin-bottom: 0.5rem;
              }
              .ml-2 {
                  margin-left: 0.5rem;
              }
              .mt-3 {
                  margin-top: 1rem;
              }
              .mb-3 {
                  margin-bottom: 1rem;
              }
              .mr-4 {
                  margin-right: 1.5rem;
              }
              .mb-4 {
                  margin-bottom: 1.5rem;
              }
              .mb-5 {
                  margin-bottom: 3rem;
              }
              .pt-0 {
                  padding-top: 0;
              }
              .pr-0 {
                  padding-right: 0;
              }
              .pb-0 {
                  padding-bottom: 0;
              }
              .py-2 {
                  padding-top: 0.5rem;
              }
              .py-2 {
                  padding-bottom: 0.5rem;
              }
              .pb-3 {
                  padding-bottom: 1rem;
              }
              .pt-4 {
                  padding-top: 1.5rem;
              }
              .px-4 {
                  padding-right: 1.5rem;
              }
              .pb-4 {
                  padding-bottom: 1.5rem;
              }
              .px-4 {
                  padding-left: 1.5rem;
              }
              .pt-5 {
                  padding-top: 3rem;
              }
              .mb-auto {
                  margin-bottom: auto;
              }
              .ml-auto {
                  margin-left: auto;
              }
              @media (min-width: 768px) {
                  .px-md-0 {
                      padding-right: 0;
                  }
                  .px-md-0 {
                      padding-left: 0;
                  }
                  .pt-md-3 {
                      padding-top: 1rem;
                  }
              }
              .text-truncate {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
              }
              .text-uppercase {
                  text-transform: uppercase;
              }
              .text-capitalize {
                  text-transform: capitalize;
              }
              .font-weight-bold {
                  font-weight: 700;
              }
              .text-primary {
                  color: #bd0e13;
              }
              a.text-primary:focus,
              a.text-primary:hover {
                  color: #76090c;
              }
              .text-dark {
                  color: #191919;
              }
              .text-white {
                  color: #fff;
              }
              .text-white-700 {
				  color: hsla(0,0%,100%,.7);
			  }
              .text-muted {
                  color: #999;
              }
           
              .fal {
                  -moz-osx-font-smoothing: grayscale;
                  -webkit-font-smoothing: antialiased;
                  text-rendering: auto;
                  display: inline-block;
                  font-style: normal;
                  font-variant: normal;
                  line-height: 1;
              }
              .bg-overlay {
                  bottom: 0;
                  height: 100%;
                  left: 0;
                  position: absolute;
                  right: 0;
                  top: 0;
                  width: 100%;
                  z-index: 1;
              }
              .bg-overlay.bg-overlay-bottom {
                  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0, transparent 50%);
                  background-repeat: repeat-x;
              }
              @media (max-width: 767.98px) {
                  .container,
                  .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-content,
                  .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-header {
                      padding-left: 1.25rem;
                      padding-right: 1.25rem;
                  }
              }
              @media (min-width: 768px) {
                  .container,
                  .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-content,
                  .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-header {
                      padding-left: 1.25rem;
                      padding-right: 1.25rem;
                  }
              }
              .row-0 {
                  margin-left: 0;
                  margin-right: 0;
              }
              .row-0 > .col-12 {
                  padding-left: 0;
                  padding-right: 0;
              }
              .row-4 {
                  margin-left: -1.5rem;
                  margin-right: -1.5rem;
              }
              .row-4 > .col-lg-4 {
                  padding-left: 1.5rem;
                  padding-right: 1.5rem;
              }
              .col-lg-3 {
					flex: 0 0 25%;
					max-width: 25%
				}
              .w-100 {
                  width: 100%;
              }
              @media (min-width: 768px) {
                  .w-md-350px {
                      width: 350px;
                  }
              }
              @media (min-width: 992px) {
                  .mw-lg {
                      max-width: 960px;
                  }
              }
              .ratio {
                  overflow: hidden;
                  position: relative;
              }
              .ratio img {
                  height: 100%;
                  -o-object-fit: cover;
                  object-fit: cover;
                  width: 100%;
              }
              .r-2-1 {
                  position: relative;
              }
              .r-2-1:before {
                  content: " ";
                  display: block;
                  padding-top: 50%;
                  width: 100%;
              }
              .r-2-1 > .outer-content {
                  bottom: 0;
                  left: 0;
                  position: absolute;
                  right: 0;
                  top: 0;
              }
              .r-2-1 > .outer-content > .inner-content {
                  height: 100%;
                  position: relative;
                  width: 100%;
              }
              .r-2-3 {
                  position: relative;
              }
              .r-2-3:before {
                  content: " ";
                  display: block;
                  padding-top: 150%;
                  width: 100%;
              }
              .r-2-3 > .outer-content {
                  bottom: 0;
                  left: 0;
                  position: absolute;
                  right: 0;
                  top: 0;
              }
              .r-2-3 > .outer-content > .inner-content {
                  height: 100%;
                  position: relative;
                  width: 100%;
              }
              .r-4-3 {
                  position: relative;
              }
              .r-4-3:before {
                  content: " ";
                  display: block;
                  padding-top: 75%;
                  width: 100%;
              }
              .r-4-3 > .outer-content {
                  bottom: 0;
                  left: 0;
                  position: absolute;
                  right: 0;
                  top: 0;
              }
              .r-4-3 > .outer-content > .inner-content {
                  height: 100%;
                  position: relative;
                  width: 100%;
              }
              .r-16-9 {
                  position: relative;
              }
              .r-16-9:before {
                  content: " ";
                  display: block;
                  padding-top: 56.25%;
                  width: 100%;
              }
              .r-16-9 > .outer-content {
                  bottom: 0;
                  left: 0;
                  position: absolute;
                  right: 0;
                  top: 0;
              }
              .r-16-9 > .outer-content > .inner-content {
                  height: 100%;
                  position: relative;
                  width: 100%;
              }
              .py-main {
                  padding-bottom: 2.5rem;
                  padding-top: 2.5rem;
              }
           
              .py-md-6 {
					padding-bottom: 6rem;
					padding-top: 6rem;
				}
              .pb-main {
                  padding-bottom: 2.5rem;
              }
              @media (min-width: 768px) {
                  .pb-main {
                      padding-bottom: 5rem;
                  }
              }
              @media (max-width: 767.98px) {
                  .px-sm-down-0 {
                      padding-left: 0;
                  }
                  .px-sm-down-0 {
                      padding-right: 0;
                  }
              }
              .breadcrumb {
                display: flex;
                flex-wrap: wrap;
                list-style: none;
                margin-bottom: 1rem;
                padding: 0.75rem 0rem;
              }
              .breadcrumb li.breadcrumb-item:not(:first-child):before {
                color: #666;
                content: "/";
                float: left;
                padding-right: 0.5rem;
              }
              .breadcrumb .breadcrumb-item a,
              .btn-link,
              .navbar-main .desktop-menu .nav-item .nav-link,
              .post-content p a,
              footer .nav-link {
                  position: relative;
              }
              .breadcrumb .breadcrumb-item a:before,
              .btn-link:before,
              .navbar-main .desktop-menu .nav-item .nav-link:before,
              .post-content p a:before,
              footer .nav-link:before {
                  background-color: #bd0e13;
                  bottom: 0.25rem;
                  content: "";
                  display: block;
                  height: 2px;
                  left: 0;
                  position: absolute;
                  -webkit-transform: scaleX(0);
                  transform: scaleX(0);
                  -webkit-transform-origin: 100% 50%;
                  transform-origin: 100% 50%;
                  width: 100%;
                  will-change: transform, color;
                  z-index: 2;
              }
              .breadcrumb .breadcrumb-item a:hover,
              .btn-link:hover,
              .navbar-main .desktop-menu .nav-item .nav-link:hover,
              .post-content p a:hover,
              footer .nav-link:hover {
                  color: #bd0e13;
              }
              .breadcrumb .breadcrumb-item a:hover:before,
              .btn-link:hover:before,
              .navbar-main .desktop-menu .nav-item .nav-link:hover:before,
              .post-content p a:hover:before,
              footer .nav-link:hover:before {
                  -webkit-transform: scaleX(1);
                  transform: scaleX(1);
                  -webkit-transform-origin: 0 50%;
                  transform-origin: 0 50%;
                  
              }
              a.card:hover .card-bg,
              a.card:hover .inner-content {
                  -webkit-transform: scale(1.06);
                  transform: scale(1.06);
              }
              @media (max-width: 767.98px) {
                  a.card .card-bg,
                  a.card .inner-content {
                      -webkit-transform: none;
                      transform: none;
                      transition: none;
                  }
                  a.card:hover .card-bg,
                  a.card:hover .inner-content {
                      -webkit-transform: none;
                      transform: none;
                  }
              }
              .nav-link {
                  cursor: pointer;
              }
              button.nav-icon,
              button.nav-link {
                  background-color: transparent;
                  border: none;
              }
              button.nav-icon:disabled,
              button.nav-link:disabled {
                  cursor: auto;
              }
              .post-content * {
                  font-size: 1.125rem;
              }
              @media (max-width: 1200px) {
                  .post-content * {
                      font-size: calc(1.0125rem + 0.15vw);
                  }
              }
              .post-content p {
                  margin-bottom: 1.125rem;
              }
              body {
                  overflow-x: hidden;
              }
              @media (prefers-reduced-motion: reduce) {
                  a {
                      transition: none;
                  }
              }
              button:focus {
                  outline: 0;
              }
              .breadcrumb {
                  background: transparent;
                  border-bottom: none;
                  padding-left: 0;
                  padding-right: 0;
              }
              .breadcrumb .breadcrumb-item {
                  font-size: 0.875rem;
              }
              .breadcrumb .breadcrumb-item a:before {
                  background-color: #333;
                  bottom: -0.25rem;
                  height: 1px;
              }
              .breadcrumb .breadcrumb-item:last-child {
                  font-weight: 700;
              }
              button:disabled {
                  cursor: default;
              }
              .btn {
                  font-size: 1rem;
                  position: relative;
              }
              .btn-link {
                  padding-left: 0;
                  padding-right: 0;
              }
              .btn-link:active,
              .btn-link:focus {
                  box-shadow: none;
              }
              .btn-link {
                  font-weight: 700;
                  text-transform: uppercase;
              }
              .btn-link:before {
                  bottom: 0.25rem;
              }
              .btn-alt-link:after,.btn-link:after {
              	  content: "";
				  border: solid;
				  border-width: 0 3px 3px 0;
				  display: inline-block;
				  padding: 3px;
				  margin-left: 3px;
				  transform: rotate(-45deg);
  				  -webkit-transform: rotate(-45deg);
				}
              .btn {
                  border-radius: 4px;
              }
              .btn-primary {
                  text-transform: uppercase;
              }
              .card {
                  overflow: hidden;
              }
              .card .col-bg {
                  overflow: hidden;
              }
              .card .col-bg .card-bg {
                  -webkit-transform: scale(1.00001);
                  transform: scale(1.00001);
              }
              .card .card-body {
                  display: flex;
                  flex-direction: column;
                  height: 100%;
                  justify-content: center;
              }
              .card .card-body .card-label {
                  color: #999;
              }
              .card .card-body .card-title {
                  color: #333;
                  margin-bottom: 0.25rem;
              }
              .card .card-body .card-title:last-child {
                  margin-bottom: 0;
              }
              .card .bg-overlay {
                  display: none;
              }
              .card-boxless {
                  background: transparent;
                  border: none;
                  box-shadow: none;
              }
              .card-boxless .card-body {
                  padding-left: 0;
                  padding-right: 0;
                  padding-top: 1rem;
              }
              .card-overlay {
                  border: none;
                  position: relative;
              }
              .card-overlay .card-bg .bg-overlay {
                  display: block;
              }
              .card-overlay .col-body {
                  height: 100%;
                  position: absolute;
                  z-index: 1;
              }
              .card-overlay .col-body .card-body {
                  align-items: flex-start;
                  justify-content: flex-end;
                  position: absolute;
                  width: 100%;
              }
              .card-overlay .col-body .card-body .card-title {
                  color: #fff;
              }
              .card {
                  border-color: #e5e5e5;
              }
              .card .card-body {
                  padding: 1.5rem;
              }
              .card-about-submenu {
                  max-width: 12rem;
              }
              .card-about-submenu .card-body {
                  padding: 0.7rem;
              }
              .form-control:disabled {
                  color: #666;
              }
              .form-control-wrapper {
                  position: relative;
              }
              .image {
                  background: #f2f2f2;
                  background: transparent;
              }
              .loader {
                  align-items: center;
                  bottom: 0;
                  display: flex;
                  height: 100%;
                  justify-content: center;
                  left: 0;
                  overflow: hidden;
                  position: fixed;
                  right: 0;
                  top: 0;
                  width: 100%;
                  z-index: 9999;
              }
              .loader .lottie {
                  width: 100px;
              }
              @media (min-width: 768px) {
                  .loader .lottie {
                      width: 150px;
                  }
              }
              .loader.light {
                  background: #fafafa;
              }
              .post-content * {
                  font-size: 1rem;
              }
              .post-content p {
                  margin-bottom: 1rem;
              }
              .post-content p a:before {
                  bottom: -2.5px;
                  height: 1px;
              }
              .caption {
                  color: #999;
                  font-size: 1rem;
                  margin-bottom: 1rem;
                  text-transform: uppercase;
              }
              .h5,
              footer h5,
              h1,
              h2,
              h4,
              h5 {
                  font-family: DM Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
              }
              h1 {
                  font-size: 2.5rem;
              }
              @media (max-width: 1200px) {
                  h1 {
                      font-size: calc(1.15rem + 1.8vw);
                  }
              }
              h2 {
                  font-size: 2rem;
              }
              @media (max-width: 1200px) {
                  h2 {
                      font-size: calc(1.1rem + 1.2vw);
                  }
              }
              h4 {
                  font-size: 1.5rem;
              }
              @media (max-width: 1200px) {
                  h4 {
                      font-size: calc(1.05rem + 0.6vw);
                  }
              }
              .h5,
              footer h5,
              h5 {
                  font-size: 1.25rem;
              }
              @media (max-width: 1200px) {
                  .h5,
                  footer h5,
                  h5 {
                      font-size: calc(1.025rem + 0.3vw);
                  }
              }
              footer h5 {
                  font-size: 1rem;
              }
              .navbar.fixed-top + main {
                  margin-top: 64px;
              }
              @media (min-width: 992px) {
                  .navbar.fixed-top + main {
                      margin-top: 104px;
                  }
              }
              .navbar {
                  display: flex;
                  flex-direction: column;
                  padding: 0;
                  width: 100%;
              }
              .navbar .col-left,
              .navbar .col-right {
                  display: flex;
                  list-style-type: none;
                  margin-bottom: 0;
                  padding: 0;
              }
              .navbar .navbar-top {
                  width: 100%;
              }
              .navbar .navbar-top .col-left,
              .navbar .navbar-top .col-right,
              .navbar .navbar-top .container {
                  height: 0;
              }
              @media (min-width: 992px) {
                  .navbar .navbar-top .col-left,
                  .navbar .navbar-top .col-right,
                  .navbar .navbar-top .container {
                      height: 32px;
                  }
                  .navbar .navbar-top .col-right {
                      margin-left: -0.75rem;
                  }
              }
              .navbar .navbar-main {
                  position: relative;
                  width: 100%;
              }
              .navbar .navbar-main .col-left,
              .navbar .navbar-main .col-right,
              .navbar .navbar-main .container,
              .navbar .navbar-main .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-content,
              .navbar .navbar-main .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-header {
                  height: 64px;
              }
              @media (min-width: 992px) {
                  .navbar .navbar-main .col-left,
                  .navbar .navbar-main .col-right,
                  .navbar .navbar-main .container,
                  .navbar .navbar-main .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-content,
                  .navbar .navbar-main .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-header {
                      height: 72px;
                  }
                  .navbar .navbar-main .col-right {
                      margin-left: 0;
                  }
              }
              .navbar-brand {
                  align-items: center;
                  display: flex;
                  height: 64px;
                  padding: 0 0 0 0.75rem;
                  width: 85px;
              }
              @media (min-width: 992px) {
                  .navbar-brand {
                      height: 72px;
                      width: 100px;
                  }
              }
              .navbar-brand img {
                  height: auto;
              }
              .navbar-main .col-left,
              .navbar-main .col-right {
                  display: flex;
              }
              .navbar-main .col-left .nav-item-brand,
              .navbar-main .col-right .nav-item {
                  position: relative;
              }
              .navbar-main .col-right .nav-icon {
                  align-items: center;
                  cursor: pointer;
                  display: flex;
                  flex-direction: column;
                  height: 64px;
                  justify-content: center;
                  width: 100%;
              }
              .navbar-main .col-right .nav-icon.absolute {
                  position: absolute;
                  z-index: 1090;
              }
              .nav-toggler .icon-bar {
                  display: block;
              }
              .nav-toggler.inactive .top-bar {
                  -webkit-transform: rotate(0);
                  transform: rotate(0);
              }
              .nav-toggler.inactive .middle-bar {
                  opacity: 1;
              }
              .nav-toggler.inactive .bottom-bar {
                  -webkit-transform: rotate(0);
                  transform: rotate(0);
              }
              .nav-toggler .top-bar {
                  -webkit-transform: rotate(45deg);
                  transform: rotate(45deg);
                  -webkit-transform-origin: 10% 10%;
                  transform-origin: 10% 10%;
              }
              .nav-toggler .middle-bar {
                  opacity: 0;
              }
              .nav-toggler .bottom-bar {
                  -webkit-transform: rotate(-45deg);
                  transform: rotate(-45deg);
                  -webkit-transform-origin: 10% 90%;
                  transform-origin: 10% 90%;
              }
              .mobile-menu {
                  height: 100vh;
                  overflow-y: scroll;
              }
              .menu-bg {
                  height: 100%;
                  left: 0;
                  pointer-events: none;
                  position: fixed;
                  top: 0;
                  width: 100%;
              }
              @media (min-width: 992px) {
                  .menu-bg {
                      display: none;
                  }
              }
              .menu-bg {
                  opacity: 0;
                  visibility: hidden;
              }
              .navbar-top .nav-link {
                  align-items: center;
                  display: flex;
                  font-size: 0.875rem;
                  height: 0;
                  padding: 0 0.75rem;
              }
              @media (min-width: 992px) {
                  .navbar-top .nav-link {
                      height: 32px;
                  }
              }
              .navbar-main .desktop-menu {
                  height: 72px;
              }
              .navbar-main .desktop-menu .nav-item {
                  display: flex;
                  width: auto;
              }
              .navbar-main .desktop-menu .nav-item .nav-link {
                  align-items: center;
                  display: flex;
              }
              .search-menu {
                  align-items: center;
                  display: flex;
                  height: 64px;
                  position: absolute;
                  top: 0;
                  width: 100%;
                  z-index: 1100;
              }
              @media (min-width: 992px) {
                  .search-menu {
                      height: 72px;
                  }
              }
              .search-menu .form-group {
                  align-items: center;
                  display: flex;
                  height: 100%;
                  margin-bottom: 0;
                  position: relative;
                  width: 100%;
              }
              .form-search {
                  width: 100%;
              }
              .form-search .form-control {
                  border-color: transparent;
                  border-radius: 2rem;
                  padding-left: 2.75rem;
              }
              .form-search .form-search-close,
              .form-search .form-search-icon {
                  height: 1.5rem;
                  line-height: 1.5rem;
                  margin-top: -0.75rem;
                  position: absolute;
                  text-align: center;
                  top: 50%;
                  width: 1.5rem;
              }
              .form-search .form-search-icon {
                  font-size: 1.125rem;
              }
              .search-menu.expand {
                  z-index: -1;
              }
              .search-menu.expand .form-group .form-search {
                  opacity: 0;
                  position: absolute;
                  right: 0;
              }
              .search-menu.expand .form-group .form-search .form-search-close {
                  opacity: 0;
              }
              .search-menu.expand.hide {
                  z-index: -1;
              }
              .search-menu.expand.hide .form-search {
                  opacity: 0;
                  
              }
              .search-menu.expand.hide .form-search .form-search-close {
                  opacity: 0;
              }
              .navbar-main.search-hide .col-left,
              .navbar-main.search-hide .col-right {
                  opacity: 1;
              }
              @media (min-width: 992px) {
                  .navbar-main.search-hide .nav-link {
                      opacity: 1;
                  }
              }
              .navbar.navbar-light .navbar-top {
                  background: #e5e5e5;
              }
              .navbar.navbar-light .navbar-top .nav-item .nav-link {
                  color: #bd0e13;
              }
              .navbar.navbar-light .navbar-top .nav-item .nav-link:hover {
                  color: #8e0a0e;
              }
              .navbar.navbar-light .navbar-main .nav-item .nav-toggler .icon-bar {
                  background: #bd0e13;
              }
              .navbar.navbar-light .navbar-main .nav-item .nav-toggler .icon-bar:hover {
                  background: #8e0a0e;
              }
              .navbar.navbar-light .navbar-main .nav-item .nav-icon,
              .navbar.navbar-light .navbar-main .nav-item .nav-link {
                  color: #bd0e13;
              }
              .navbar.navbar-light .navbar-main .nav-item .nav-icon:hover,
              .navbar.navbar-light .navbar-main .nav-item .nav-link:hover {
                  color: #8e0a0e;
              }
              .navbar .navbar-top .col-left {
                  margin-left: -0.75rem;
              }
              @media (min-width: 992px) {
                  .navbar .navbar-top .col-left {
                      margin-left: -0.75rem;
                  }
              }
              .navbar .navbar-top .col-right {
                  margin-right: -0.75rem;
              }
              @media (min-width: 992px) {
                  .navbar .navbar-top .col-right {
                      margin-right: -0.75rem;
                  }
              }
              .navbar .navbar-main .col-left {
                  margin-left: -0.75rem;
              }
              @media (min-width: 992px) {
                  .navbar .navbar-main .col-left {
                      margin-left: -0.75rem;
                  }
              }
              .navbar .navbar-main .col-right {
                  margin-right: -0.75rem;
              }
              @media (min-width: 992px) {
                  .navbar .navbar-main .col-right {
                      margin-right: 0;
                  }
              }
              .navbar-brand {
                  padding-left: 0.75rem;
                  width: 9rem;
              }
              @media (min-width: 992px) {
                  .navbar-brand {
                      width: 150px;
                  }
              }
              .navbar-main .col-right .nav-item {
                  width: 3rem;
              }
              .navbar-main .col-right .nav-icon .air {
                  top: 1px;
              }
              .navbar-main .col-right .nav-icon i {
                  font-size: 1.125rem;
              }
              .nav-toggler .icon-bar {
                  border-radius: 2px;
                  height: 2px;
                  width: 22px;
              }
              .nav-toggler .icon-bar + .icon-bar {
                  margin-top: 4px;
              }
              .nav-toggler .icon-bar.middle-bar {
                  margin-right: -0.25rem;
                  width: 50%;
              }
              .mobile-menu {
                  background: #fff;
                  position: absolute;
                  top: 0;
                  width: 100%;
              }
              .mobile-menu .mobile-menu-content {
                  padding: 5rem 0;
              }
              .mobile-menu .mobile-menu-content .subnavbar-nav {
                  flex-direction: column;
                  padding-left: 1rem;
              }
              .mobile-menu .mobile-menu-content .gfx-1,
              .mobile-menu .mobile-menu-content .gfx-2,
              .mobile-menu .mobile-menu-content .gfx-3 {
                  opacity: 0.03;
                  position: absolute;
                  right: 0;
                  width: 100%;
              }
              .mobile-menu .mobile-menu-content .gfx-1 {
                  top: 9rem;
              }
              .mobile-menu .mobile-menu-content .gfx-2 {
                  top: 17rem;
              }
              .mobile-menu .mobile-menu-content .gfx-3 {
                  top: 25rem;
              }
              .mobile-menu .nav-item {
                  overflow: hidden;
              }
              .mobile-menu .nav-item .nav-link {
                  color: #191919;
                  font-size: 1.5rem;
                  font-weight: 700;
              }
              .mobile-menu .nav-item .nav-link.back-link {
                  font-size: 1rem;
                  text-transform: uppercase;
              }
              .mobile-menu .nav-mobile-btn {
                  font-weight: 700;
              }
              .navbar-top .nav-link {
                  letter-spacing: 1px;
                  padding: 0 0.25rem;
              }
              .navbar-main .desktop-menu {
                  align-items: center;
                  position: relative;
              }
              .navbar-main .desktop-menu .nav-item {
                  margin-left: 1rem;
                  padding-left: 0.5rem;
                  padding-right: 0.5rem;
              }
              .navbar-main .desktop-menu .nav-item .nav-link {
                  font-weight: 500;
                  padding-left: 0;
                  padding-right: 0;
              }
              .navbar-main .desktop-menu .nav-item .nav-link i {
                  font-size: 1.125rem;
              }
              .search-menu {
                  background: transparent;
              }
              .form-search .form-control {
                  background: #fff;
                  border: 1px solid transparent;
                  border-bottom-color: #e5e5e5;
                  border-radius: 0;
                  padding-left: 0.5rem;
              }
              .form-search .form-search-icon {
                  font-size: 1.25rem;
                  left: 0.75rem;
              }
              .form-search .form-search-icon.mobile {
                  color: #191919;
                  font-weight: 400;
                  left: unset;
                  right: 0;
              }
              .form-search .mobile-search {
                  border: unset;
                  border-bottom: 1px solid #ccc;
                  border-radius: unset;
                  padding-left: 0;
                  width: 100%;
              }
              .form-search .form-search-close {
                  background: #e5e5e5;
                  border-radius: 2rem;
                  cursor: pointer;
                  font-size: 0.875rem;
                  right: 0.7rem;
              }
              .form-search .form-search-close:active,
              .form-search .form-search-close:focus,
              .form-search .form-search-close:hover {
                  background: #ccc;
              }
              @media (min-width: 992px) {
                  .search-menu.expand .form-group {
                      right: 120px;
                  }
              }
              .search-menu.expand .form-group .form-search,
              .search-menu.expand.hide .form-search {
                  width: 80px;
              }
              .navbar.navbar-light .navbar-brand .logo-light {
                  display: none;
              }
              .navbar.navbar-light .navbar-brand .logo-dark {
                  display: block;
              }
              .navbar.navbar-light .nav-toggler .icon-bar {
                  background-color: #191919;
              }
              .navbar.navbar-light .navbar-top {
                  background: #191919;
              }
              .navbar.navbar-light .navbar-top .nav-item .nav-link,
              .navbar.navbar-light .navbar-top .nav-item .nav-link:hover {
                  color: #fff;
              }
              .navbar.navbar-light .navbar-main {
                  background: #fff;
              }
              .navbar.navbar-light .navbar-main .nav-item .nav-toggler .icon-bar {
                  background: #191919;
              }
              .navbar.navbar-light .navbar-main .nav-item .nav-toggler .icon-bar:hover {
                  background: #000;
              }
              .navbar.navbar-light .navbar-main .nav-item .nav-icon,
              .navbar.navbar-light .navbar-main .nav-item .nav-link {
                  color: #4d4d4d;
              }
              .navbar.navbar-light .navbar-main .nav-item .nav-icon:hover,
              .navbar.navbar-light .navbar-main .nav-item .nav-link:hover {
                  color: #bd0e13;
              }
              .mobile-menu {
                  background: #191919;
              }
              .mobile-menu .nav-item .nav-link {
                  color: #fff;
              }
              .navbar-submenu {
                  bottom: 0;
                  display: none;
                  height: 100%;
                  left: 0;
                  position: fixed;
                  right: 0;
                  top: 0;
                  width: 100%;
                  z-index: 1090;
              }
              @media (min-width: 992px) {
                  .navbar-submenu {
                      bottom: 0;
                      left: 0;
                      position: fixed;
                      right: 0;
                      top: 102px;
                  }
              }
              .navbar-submenu .navbar-submenu-bg {
                  background: rgba(0, 0, 0, 0.75);
                  bottom: 0;
                  height: 100%;
                  left: 0;
                  position: fixed;
                  right: 0;
                  top: 0;
                  width: 100%;
                  z-index: -1;
              }
              @media (min-width: 992px) {
                  .navbar-submenu .navbar-submenu-bg {
                      bottom: 0;
                      left: 0;
                      position: fixed;
                      right: 0;
                      top: 102px;
                  }
              }
              .navbar-submenu .navbar-submenu-content {
                  background: #fff;
                  background-image: linear-gradient(180deg, rgba(25, 25, 25, 0.05) 0, #fff 2%);
                  background-repeat: repeat-x;
                  height: 100%;
                  overflow: hidden;
                  position: relative;
              }
              .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse {
                  width: 100%;
              }
              @media (max-width: 991.98px) {
                  .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse {
                      bottom: 0;
                      overflow-x: hidden;
                      overflow-y: scroll;
                      position: fixed;
                      top: 0;
                      width: 100%;
                  }
              }
              .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-header {
                  align-items: center;
                  display: flex;
                  height: 128px;
              }
              @media (min-width: 992px) {
                  .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-header {
                      display: none;
                  }
              }
              .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-header .btn {
                  color: #333;
              }
              .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-content {
                  padding-bottom: 2rem;
                  padding-top: 2rem;
              }
              .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-content h2 {
                  color: #000;
              }
              .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-content .navbar-nav {
                  flex-direction: column;
              }
              .navbar-submenu .navbar-submenu-content .navbar-submenu-collapse-content .navbar-nav .nav-item {
                  overflow: hidden;
              }
              footer .nav-link {
                  display: inline-block;
              }
              @media (max-width: 991.98px) {
                  .footer-main .col-footer {
                      margin-bottom: 2.5rem;
                  }
                  .footer-main .col-footer:last-child {
                      margin-bottom: 0;
                  }
              }
              .footer-menu .nav-link {
                  color: #333;
              }
              @media (max-width: 767.98px) {
                  .footer-menu .nav-link {
                      font-size: 1rem;
                  }
              }
              .footer-menu .nav-link:active,
              .footer-menu .nav-link:focus,
              .footer-menu .nav-link:hover {
                  color: #bd0e13;
              }
              .footer-bottom {
                  padding-bottom: 2.5rem;
                  padding-top: 0;
              }
              .footer-bottom .container {
                  display: flex;
                  justify-content: space-between;
              }
              .footer-bottom .divider {
                  color: #fff;
                  margin: 0 0.375rem;
              }
              @media (min-width: 768px) {
                  .footer-bottom .col-right {
                      text-align: right;
                  }
              }
              .social {
                  display: block;
                  margin: 0 0 0 -0.125rem;
              }
              .social .social-item {
                  display: inline-block;
                  margin-right: 0.55rem;
              }
              .social .social-item:last-child {
                  margin-right: 0;
              }
              .social .social-link {
                  align-items: center;
                  display: flex;
                  justify-content: center;
              }
              .social .social-link i {
                  font-size: 1rem;
              }
              .social .social-link:focus,
              .social .social-link:hover {
                  color: #fff;
              }
              footer {
                  background-color: #191919;
                  position: relative;
              }
              footer h5 {
                  color: #fff;
              }
              footer .red-ribbon {
                  display: flex;
                  justify-content: flex-end;
                  position: absolute;
                  position: relative;
                  top: -24px;
                  width: 100%;
              }
              footer .red-ribbon .ribbon {
                  bottom: 0;
                  height: 49px;
                  position: absolute;
              }
              @media (min-width: 768px) {
                  footer .red-ribbon .ribbon {
                      height: 68px;
                  }
              }
              footer .red-ribbon .ribbon.red {
                  background: #dd1b20;
                  left: 0;
                  width: calc(100% - 180px);
                  z-index: 1;
              }
              @media (min-width: 768px) {
                  footer .red-ribbon .ribbon.red {
                      width: calc(100% - 247px);
                  }
              }
              footer .red-ribbon .ribbon.black {
                  background: #191919;
                  bottom: 0;
                  right: 0;
                  width: 180px;
                  z-index: 2;
              }
              @media (min-width: 768px) {
                  footer .red-ribbon .ribbon.black {
                      width: 247px;
                  }
              }
              footer .red-ribbon img {
                  height: 65px;
                  z-index: 3;
              }
              @media (min-width: 768px) {
                  footer .red-ribbon img {
                      height: 90px;
                  }
              }
              .footer-menu .nav-link {
                  color: #fff;
              }
              .footer-contact p {
                  color: #fff;
                  opacity: 1;
              }
              .social .social-link {
                  border-radius: 50%;
                  color: #fff;
                  height: 2.25rem;
                  line-height: 2.25rem;
                  text-align: center;
                  width: 2.25rem;
              }
              .social .social-link i {
                  font-size: 1.8rem;
              }
              .social .social-link:active,
              .social .social-link:focus,
              .social .social-link:hover {
                  color: #fff;
              }
              .footer-bottom {
                  background-color: #000;
                  font-size: 14px;
                  padding: 1.5rem 0;
              }
              .footer-bottom .footer-brand {
                  color: #fff;
                  opacity: 0.7;
              }
              .footer-bottom a {
                  color: #fff;
              }
              li {
                  list-style: none;
                  margin-bottom: 20px;
                  margin-left: 10px;
                  margin-right: 10px;
              }
              
              amp-sidebar {
                width: 80vw;
                max-width: unset;
                padding-right: 10px;
                background-color: white;
                padding-left: 1rem;
              }
              .amp-sidebar-image {
                line-height: 100px;
                vertical-align: middle;
              }
              .amp-close-image {
                top: 15px;
                left: 225px;
                cursor: pointer;
              }
              li {
                margin-bottom: 20px;
                margin-left: 10px;
                margin-right: 10px;
                list-style: none;
              }
              .previewOnly {
                font-weight: bold;
              }
              #sidebar-right nav.amp-sidebar-toolbar-target-shown {
                display: none;
              }
              .amp-sidebar-mask{
                z-index: 1;
              }
              .social {
                display: flex;
                align-items: center;
              }
              .social-amp-item svg path {
                fill: white;
              }
              .social-amp-item {
                margin-bottom: 0;
              }
              .social-amp-item .social-link {
                width: 25px;
                height: 25px;
              }
              .social-amp-item .social-link.gtm-youtube {
              }
              
              .heading.heading-link {
				align-items: center;
				display: flex;
				justify-content: space-between;
			  }
			  
			  .global-marketingGallery {
					background-color: #2c2e3c;
					color: #fff;
					position: relative
				}
				
				.global-marketingGallery .bg-image {
					bottom: 0;
					opacity: .025;
					position: absolute;
					right: 0
				}
				
				.global-marketingGallery .bg-image.curve-3 {
					height: 100%
				}
				
				.global-marketingGallery .bg-image.curve-2 {
					height: 85%
				}
				
				.global-marketingGallery .bg-image.curve-1 {
					height: 52%
				}
				
				.sc-projectDetails-gallery {
					overflow: hidden;
					position: relative;
				}
				
				.sc-projectDetails-gallery .gallery-bg {
					background-color: #2c2e3c;
					bottom: 0;
					height: 200px;
					position: absolute;
					width: 100%;
					z-index: -10
				}
				
				@media (min-width: 768px) {
					.sc-projectDetails-gallery .gallery-bg {
						height:400px
					}
				}
				
				.sc-project-infrastructure .text-infrastructure {
					color: #404041;
					font-weight: 700;
				}
				.ml-4, .mx-4 {
					margin-left: 1.5rem;
				}
				.mb-0, .my-0 {
					margin-bottom: 0;
				}
            `}
				</style>
				<script async src="https://cdn.ampproject.org/v0.js"></script>
				<script
					async
					custom-element="amp-form"
					src="https://cdn.ampproject.org/v0/amp-form-0.1.js"
				></script>
				{/* <script
          async
          custom-element="amp-mega-menu"
          src="https://cdn.ampproject.org/v0/amp-mega-menu-0.1.js"
        ></script> */}
				<script
					async
					custom-element="amp-sidebar"
					src="https://cdn.ampproject.org/v0/amp-sidebar-0.1.js"
				></script>
				<script
					async
					custom-element="amp-social-share"
					src="https://cdn.ampproject.org/v0/amp-social-share-0.1.js"
				></script>
				<script
					async
					custom-element="amp-analytics"
					src="https://cdn.ampproject.org/v0/amp-analytics-0.1.js"
				></script>
				<script
					async
					custom-element="amp-carousel"
					src="https://cdn.ampproject.org/v0/amp-carousel-0.1.js"
				></script>
			</Helmet>
			<SEO
				title={seo.title}
				desc={seo.metaDesc}
				fbImg={SEODefaultImg}
				fbTitle={seo.opengraphTitle}
				fbDesc={seo.opengraphDescription}
				url={redirectAmpHtml}
				twitterTitle={seo.twitterTitle}
				twitterDesc={seo.twitterDescription}
				twitterImg={SEODefaultImg}
				schema={schemaMarkup}
				amp
				ampSideBar
			/>
			<MicrodataProject
				title={seo.title}
				description={seo.metaDesc}
				url={location.href}
				image={SEODefaultImg}
				priceRange={datalayerAdd?.preferredPriceRange}
			/>
			<ProjectDetailsDataProject data={data.cpi} />
			<div className="container mw-xl">
				<Breadcrumb path={location.pathname}></Breadcrumb>
			</div>
			{/*<GlobalCover breadcrumb={location.pathname} data={data.cover} theme={bgColor || "light"} />*/}
			<ProjectDetailsHeadline
				data={data.headline}
				slug={slug}
				title={data.cover?.title}
				location={location}
				eBrochures={eBrochures}
				city={projectLocations}
				lang={lang}
				datalayerAdd={datalayerAdd}
			/>
			<section className="py-main sc-projectDetails-gallery">
				<div className="gallery-bg" />
				<div className="container">
				<amp-carousel
					width="768"
					height="480"
					layout="responsive"
					type="slides"
					role="region"
					aria-label="Basic carousel"
				>
					{data.gallery.image.map((value, i) => {
						const image = value.sourceUrl
						return (
							<amp-img
								src={image}
								width="768"
								height="480"
							></amp-img>
						)
					})}
				</amp-carousel>
				</div>
			</section>
			{/*<ProjectDetailsGallery data={data.gallery} />*/}
			<ProjectDetailsTenants data={data.tenants} location={location.pathname} lang={lang} isAmp/>
			<ProjectDetailsInfrastructure data={data.infrastructure} />
			<ProjectDetailsVirtualTour data={data.virtualTour} />
			{/*<YoutubeFrame link={data.video} thumbnail={data.videoThumbnail} isAmp/>*/}
			<Map className="w-100 h-500px" lat={latitude} long={longitude} />
			<ProjectDetailsLocation data={data.location} />
			{/*<ProjectDetailsNearby data={nearbyData} loc={pageContext?.data?.projectLocations?.nodes[0]?.name} path={location.pathname} slug={slug} />*/}
			<GlobalMarketingGallery data={data.marketingGallery} />

			<section className="py-main sc-projectDetails-leadForm">
				<div className="container">
					<div className="row">
						<h2>{lang === "en" ? "Get More Information" : "Informasi Lebih Lanjut"}</h2>
					</div>
					<div className="row">
						<a className="btn btn-link scroll-fadeInUp fadeInUp delayp3"
						   href={redirectAmpHtml.replace("/development/amp/", "/development/")} target="_blank">Get More Information</a>
					</div>
				</div>
			</section>

			{/*<ProjectDetailsLeadForm*/}
			{/*	lang={lang}*/}
			{/*	cpi={data.cpi}*/}
			{/*	title={data.cover?.title}*/}
			{/*	utmId={utmId}*/}
			{/*	slug={slug}*/}
			{/*	city={projectLocations}*/}
			{/*	propertyType={projectCategories}*/}
			{/*	datalayerAdd={datalayerAdd}*/}
			{/*/>*/}
			<NavBottom
				lang={lang}
				prevTitle={coverBottom.prev?.title}
				prevImg={coverBottom.prev?.image}
				prevLink={`${coverBottom.prev?.path}`}
				nextTitle={coverBottom.next?.title}
				nextImg={coverBottom.next?.image}
				nextLink={`${coverBottom.next?.path}`}
			/>
		</Layout>
	)
}

export default WithLocation(ProjectDetails)

